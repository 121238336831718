.sticky-footer-banner,
.sticky-footer-content,
.sticky-footer-title,
.sticky-footer-cta {
  display: none;
}

.js-non-loyalty .mac-3-column-layout {
  display: inline-block;
  width: 32%;
  padding: 1%;
}

.js-non-loyalty .mac-3-column-layout.sticky-footer-cols:nth-child(3) {
  border-left: solid 1px;
  border-right: solid 1px;
}

.js-non-loyalty .mac-2-column-layout {
  display: inline-block;
  width: 48%;
  padding: 1%;
}

.js-non-loyalty .mac-2-column-layout.sticky-footer-cols:nth-child(2) {
  border-right: solid 1px;
}
